import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation, Scrollbar } from "swiper";

import "swiper/css";

export default function Swipe({ imgs }) {
  const durations = [6000, 6500, 7000, 7500, 8000, 8500, 9000];
  const swiperRef = useRef(null);
  const handleMouseMove = (e) => {
    const imgWidth = e.target.offsetWidth;
    const mouseXRelativeToImg =
      e.clientX - e.target.getBoundingClientRect().left;

    if (mouseXRelativeToImg < imgWidth / 2 && imgs.length > 1) {
      e.target.style.cursor = "url(YOUR_LEFT_ARROW_IMAGE_URL), w-resize";
    } else if (mouseXRelativeToImg > imgWidth / 2 && imgs.length > 1) {
      e.target.style.cursor = "url(YOUR_RIGHT_ARROW_IMAGE_URL), e-resize";
    } else {
      e.target.style.cursor = "default";
    }
  };

  const handleMouseLeave = () => {
    document.body.style.cursor = "default"; // reset to default cursor when mouse leaves
  };

  const handleMouseClick = (e) => {
    const imgWidth = e.target.offsetWidth;
    const mouseXRelativeToImg =
      e.clientX - e.target.getBoundingClientRect().left;

    if (mouseXRelativeToImg < imgWidth / 2) {
      swiperRef.current?.swiper.slidePrev();
    } else {
      swiperRef.current?.swiper.slideNext();
    }
  };

  return (
    <>
      <Swiper
        ref={swiperRef}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation, Scrollbar]}
        spaceBetween={1}
        loop={true}
        centeredSlides={true}
        lazyPreloaderClass="swiper-lazy-preloader"
        lazyPreloadPrevNext={1}
        autoHeight={true}
        // autoplay={{
        //   delay: durations[parseInt(Math.random() * durations.length)],
        //   pauseOnMouseEnter: true,
        // }}
        // pagination={{
        //   clickable: true,
        //   renderBullet: function (index, className) {
        //     return imgs.length > 1
        //       ? '<span class="' + className + '">' + (index + 1) + "</span>"
        //       : "";
        //   },
        // }}
        className="mySwiper"
        // mobile

        scrollbar={{
          hide: true,
        }}
        breakpoints={{
          768: {},
        }}
      >
        {imgs.map((img, id) => (
          <SwiperSlide
            key={id}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            // bug
            // onClick={handleMouseClick}
          >
            <img src={"assets/imgs/" + img} alt={img} key={img} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
