import "react-clock/dist/Clock.css";
import Hero from "./module/Hero";
import PostSection from "./module/PostSection";
import Error from "./module/Error";
import Form from "./module/Form";
import BadDiary from "./BadDiary/BadDiary";
import MarqueeSecrion from "./module/MarqueeSecrion";

import React, { useEffect, useState } from "react";
import Nav from "./module/Nav";

import Sketch from "./module/Sketch";
import FooterIframe from "./module/FooterBadvisor";
import Graph from "./module/Graph";

window.addEventListener(
  "resize",
  function (event) {
    // window.location.reload();
  },
  true
);

function App() {
  return (
    <div className={"App "} id="home">
      {/* <Nav /> */}
      {/* <Sketch /> */}
      {/* {Math.random() < 0.5 ? <Graph /> : <Sketch />} */}
      <Hero />
      {/* switch randomize renderer */}
      <PostSection />
      <br></br> <br></br>
      {/* <BadDiary /> */}
      {/* <Form /> */}
      {/* <MarqueeSecrion /> */}
      {/* <FooterIframe /> */}
      {/* <video autoPlay muted loop id="myVideo" src="/assets/imgs/perlRec.mov" /> */}
      <Error />
    </div>
  );
}

export default App;
