import React from "react";
import Posts from "./Posts";

export default function PostSection() {
  return (
    <>
      <div className="container" id="selected">
        <section>
          <hr />
          <p className="fixedP">
            Interno 9
            <br />
            Blending web developement, computer graphics and UI design to create
            immmersive websites for clients of any type. Based in Switzerland.
          </p>
        </section>

        <section id="info">
          <hr />
          <Posts />
        </section>
      </div>
    </>
  );
}
