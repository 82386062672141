import React, { useEffect, useRef } from "react";
import Post from "./Post";
import anime from "animejs";

export default function Posts() {
  const animationDomino = useRef(null);

  useEffect(() => {
    if (window.innerWidth < 700) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            anime({
              targets: entry.target,
              opacity: [0, 1],
              // translateY: ["25%", 0],
              duration: 1000,
              easing: "easeOutQuart",
            });

            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.0001 }
    );

    const children = Array.from(animationDomino.current.children);
    if (children.length === 0) {
      console.log("No children to observe");
      return;
    }

    children.forEach((child) => {
      observer.observe(child);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div ref={animationDomino}>
      {/* <Post
        title={"Custer Waller"}
        urlClean={"onfinance.ch"}
        url={"https://www.onfinance.ch"}
        imgs={[
          "onfinance-1.jpg",
          "onfinance-2.jpg",
          "onfinance-3.jpg",
          "onfinance-4.jpg",
          "onfinance-5.jpg",
        ]}
        description="On Finance's website, separated in two parts, the website is a portal to OF platorms service and customer's infomation center. With onfinance.ch Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud "
      /> */}

      {/* <Post
        title={"Ephoto."}
        urlClean={"demo"}
        url={"https://app.badvisor.io/e-photo/arte/lucchini-g_02"}
        imgs={[
          "lucchini/lucchini3.webp",
          "lucchini/lucchini4.webp",
          "lucchini/lucchini5.webp",
          "lucchini/lucchini6.webp",
        ]}
        description="For Milano Salone Del Mobile, a digital catalog for Lucchini' sculture collection (Gold, Totem and Haute Couture series) ."
      /> */}

      <Post
        title={"George Best"}
        urlClean={"georgebest.com"}
        url={"https://georgebest.com"}
        imgs={[
          "best/img1.webp",
          "best/img2.webp",
          "best/img3.webp",
          "best/img4.webp",
          "best/img5.webp",
        ]}
        description={`"A footballer with talent and charisma beyond anything the world had ever seen, the Working Class boy from the streets of Belfast became a cultural phenomenon at the height of the Sixties, inspiring legions of fans with his style on and off the pitch." George Best's website, both e-commerce history digital archive.`}
      />

      <Post
        title={"Libreria Wälti"}
        urlClean={"libreriawaelti.ch"}
        url={"https://libreriawaelti.ch"}
        imgs={[
          "waelti/img1.webp",
          "waelti/img2.webp",
          "waelti/img3.webp",
          "waelti/img4.webp",
          "waelti/img5.webp",
          "waelti/img6.webp",
          "waelti/img7.webp",
          "waelti/img8.webp",
        ]}
        description='"Ritual, Symbol, Tradition, Poetry. Along these four tracks runs the path to knowledge proposed by the Wälti Bookstore." A digital presence for an historical library that sells bigger than earths treasures.'
      />

      <Post
        title={"Badvisor.io"}
        urlClean={"badvisor.ch"}
        url={"https://badvisor.io"}
        imgs={["badvisor-1.webp"]}
        description="Working for Badvisor, a all in one web 3D solution for any business. You can revolutionize your web design and customer engagement with our 3D SaaS platform."
      />

      <Post
        title={"Liner tool"}
        urlClean={"link"}
        url={"https://interno9.netlify.app/liner/"}
        imgs={[
          "liner/liner_screenFri Dec 01 2023 17_17_10 GMT+0100 (Central European Standard Time).webp",
          "liner/liner_screenFri Dec 01 2023 17_17_48 GMT+0100 (Central European Standard Time).webp",
          "liner/liner_screenFri Dec 01 2023 17_19_07 GMT+0100 (Central European Standard Time).webp",
          "liner/liner_screenFri Dec 01 2023 18_17_48 GMT+0100 (Central European Standard Time) copy.webp",
        ]}
        description="Liner: 
        A graphic tool I built trying to replicate a photoshop trick I couldn't do. Inspiration came from a book my mom gifted me
        (Art Stage Singapore 22-25 Jan '15). Liner follows the user's Y/X-mouse coordinate and melts the picture using a copy() and map() algorithm. Screens can be saved and reused for your birthday party card. Special thanks to the swiss photographer Liam Barbieri. (Mobile not supposed) 
        
        "
      />

      <Post
        title={".ply to .splat optimizer tool"}
        urlClean={"badvisor-splat.web.app"}
        url={"https://badvisor-splat.web.app/"}
        imgs={[
          "splatTool/splatTool.gif",
          "splatTool/splatTool1.webp",
          "splatTool/splatTool2.webp",
          "splatTool/splatTool3.webp",
          "splatTool/scan.gif",

          "splatTool/scanInBad.webp",
        ]}
        description="The .ply to .splat optimizer tool it's an online part of badvisor.io. Letting users convert and optimize to Gaussian Splatting format (.splat). Later pictures shows how a video* can convert to a Gsplat scan. Finally updated in badvisor.io  (slide 5 - special thanks to SerVIRUS Youtube video, 'Flying around the MotherMotherland from a height of 250 meters.'"
      />

      <Post
        title={"Random"}
        urlClean={"badvisor.io"}
        url={"https://badvisor.io"}
        imgs={[
          // "sahderVid2.gif",
          // "sahderVid3.gif",
          "shader1.webp",
          "shader2.webp",
          "shader3.webp",
          "shader4.webp",
        ]}
        description={`Tests with glassy shader custom materials we built for real time rendering in Badvisor. Listed are some tests here and there.
       `}
      />

      <Post
        title={"Random"}
        // urlClean={"onfinance.ch"}
        // url={"https://www.onfinance.ch"}
        imgs={[
          "softwarescreen.webp",
          "aecGift.webp",
          "usecases.webp",
          "wireframe.webp",
          "myflowers.webp",
          // "myflowers2.webp",
          "mhsLogo.webp",
          "logoLiner.webp",
          "paletteButtons.webp",
          "pointCloud.webp",
          // "chrisWildlife.webp",
        ]}
        description={`Random diary of commissions, screens and other pretty stuff. Behing the scenes and work in progress. Mostly coded or drawn between 2023 summer and fall.`}
      />

      <Post
        title={"On Finance"}
        urlClean={"onfinance.ch"}
        url={"https://www.onfinance.ch"}
        imgs={[
          "onfinance-1.jpg",
          "onfinance-2.jpg",
          "onfinance-3.jpg",
          "onfinance-4.jpg",
          "onfinance-5.jpg",
        ]}
        description="On Finance's website, separated in two parts, the website is a portal to OF platorms service and customer's infomation center."
      />

      <Post
        title={"Carolina Sulis' portfolio website"}
        urlClean={"carolinasulis.ch"}
        url={"https://www.carolinasulis.ch"}
        imgs={[
          "carolinasulis1.webp",
          "carolinasulis2.webp",
          "carolinasulis3.webp",
          "carolinasulis4.webp",
        ]}
        description="Beloved ex collegue Carolina Sulis website. Carolina' web portfolio has been created with a CMS dynamism fashion to let her update independently (through a panel within the site) her awesome 3D works and reders."
      />

      <Post
        title={"Schloss Gruenenstein"}
        urlClean={"schloss-gruenenstein.ch"}
        url={"https://www.schloss-gruenenstein.ch"}
        imgs={[
          "schloss-1.webp",
          "schloss-2.gif",
          "schloss-3.webp",
          "schloss-4.webp",
          "schloss-5.webp",
          "schloss-6.webp",
          "schloss-7.webp",
          "schloss-8.webp",
          "schloss-9.webp",
        ]}
        description="schloss-gruenenstein.ch is a cms website 2022. 'Welcome to the residential house of Jacob Laurenz Custer. A lively castle with rooms in the medieval tower, and with private apartments. Welcome, come in!'"
      />

      <Post
        title={"Flavia Brändle"}
        urlClean={"flaviabraendle.ch"}
        url={"https://www.flaviabraendle.ch"}
        imgs={[
          "flaviabraendle-1.webp",
          "flaviabraendle-2.webp",
          "flaviabraendle-3.webp",
          "flaviabraendle-4.webp",
          "flaviabraendle-5.webp",
          "flaviabraendle-6.webp",
        ]}
        description="Discover Flavia Brändle's dynamic website - a CMS-powered portfolio and blog showcasing her exceptional work as an industrial designer. Experience an ever-changing layout, explore captivating posts, and witness the evolution of her portfolio. Immerse yourself in Flavia's innovative design approach on her engaging website."
      />

      <Post
        title={"Ristorante Cortivallo"}
        urlClean={"ristorantecortivallo.ch"}
        url={"https://ristorantecortivallo.ch"}
        imgs={["ristorantecortivallo-1.webp"]}
        // description="Local owned restaurant, truly the best meats in Lugano."
      />
    </div>
  );
}
